import axios from '@/axios.js'

export default {
  fetchPlaceCategories ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/place-category')
        .then(({data: response}) => {
          commit('SET_PLACE_CATEGORIES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPlaceCategory (context, placeCategoryId) {
    return new Promise((resolve, reject) => {
      axios.get(`/place-category/${placeCategoryId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storePlaceCategory (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/place-category', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updatePlaceCategory (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/place-category/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyPlaceCategory ({ commit }, placeCategoryId) {
    return new Promise((resolve, reject) => {
      axios.delete(`place-category/${placeCategoryId}`)
        .then((response) => {
          commit('REMOVE_RECORD', placeCategoryId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
